<template>
  <div style="margin-top: 1%">
<!--    会议召开单位-->
    <div style="display: flex">
      <el-select v-model="meetdw" class="m-2" placeholder="请选择召开单位" size="mini" style="margin-left: 30px;margin-right: 40px">
        <el-option
            v-for="item in meetdws"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-input v-model="meetname" size="mini" style="width: 400px;margin-right: 40px" placeholder="请输入会议名称" clearable />
      <el-date-picker
          v-model="datetime"
          type="date"
          placeholder="请选择开会日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          size="mini"
          style="width: 150px"
      />
      <el-time-select
          v-model="detailtime"
          start="07:00"
          step="00:10"
          end="23:50"
          placeholder="请选择具体时间"
          size="mini"
          style="width: 155px"/>
    </div>
<!--    选择人员信息-->
    <div style="margin-top: 20px">
      <el-select v-model="meetbdst" class="m-2" placeholder="请选择参会单位" size="mini" style="margin-left: 30px;margin-right: 40px" @change="getDepartOrNameByBdst()">
        <el-option
            v-for="item in meetbdsts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </div>
<!--    人员信息表格-->
    <div style="display: flex;justify-content: space-around;margin-top: 20px">
<!--      查询出的人员-->
      <div v-if="this.meetbdst === '项目公司'">
        <vxe-table
            border
            resizable
            :align="allAlign"
            :data="selectMeetUser"
            size="small"
            highlight-hover-row
            ref="xTable1"
            :export-config="{}"
        >
          <vxe-column field="name" title="姓名" width="300"></vxe-column>
          <vxe-column title="操作" width="100" show-overflow>
            <template #default="{ row }">
              <vxe-button size="mini" status="primary" @click="getSelectionEvent(row)">选择</vxe-button>
            </template>
          </vxe-column>
        </vxe-table>
      </div>

      <div v-if="this.meetbdst !== '项目公司'">
        <vxe-table
            border
            resizable
            :align="allAlign"
            :data="selectMeetUser"
            size="small"
            highlight-hover-row
            ref="xTable1"
            :export-config="{}"
        >
          <vxe-column field="name" title="姓名" width="300"></vxe-column>
          <vxe-column title="操作" width="100" show-overflow>
            <template #default="{ row }">
              <vxe-button size="mini" status="primary" @click="getSelectionEvent(row)">选择</vxe-button>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
<!--      已选择的人员-->
      <div style="width: 300px;word-wrap:break-word;">
        <el-tag style="width: 300px;">已选择人员\部门</el-tag>
        <br/>
        <el-button v-for="(item, index) in selectedMeetUser" type="warning" size="mini" style="margin-top: 20%" @click="delMeetUser(item)">{{ item }}</el-button>
      </div>
    </div>
<!--    提交按钮-->
    <div style="float: right;margin-right: 50px">
      <el-button size="mini" type="danger" @click="upMeet">提交</el-button>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
  name: "add_meet",
  data(){
    return{
      meetdw: '',
      meetdws: [
        {
          value: '集团公司',
          label: '集团公司',
        },
        {
          value: '股份公司',
          label: '股份公司',
        },
        {
          value: '项目公司',
          label: '项目公司',
        },
        {
          value: '康定项目部',
          label: '康定项目部',
        },
        {
          value: '四川公司',
          label: '四川公司',
        },
        {
          value: '川藏公司',
          label: '川藏公司',
        },
        {
          value: '川藏股指',
          label: '川藏股指',
        },
      ],
      meetname: '',
      detailtime:'',
      datetime: '',
      meetuser: '',
      meetbdst: '项目公司',
      meetbdsts: [
        {
          value: '项目公司',
          label: '项目公司',
        },
        {
          value: '二标一工区、五标六工区',
          label: '二标一工区、五标六工区',
        },
        {
          value: '二标二工区',
          label: '二标二工区',
        },
        {
          value: '五标一工区',
          label: '五标一工区',
        },
        {
          value: '五标二工区',
          label: '五标二工区',
        },
        {
          value: '五标三工区',
          label: '五标三工区',
        },
        {
          value: '五标四工区',
          label: '五标四工区',
        },
        {
          value: '五标五工区',
          label: '五标五工区',
        },
        {
          value: '五标七工区',
          label: '五标七工区',
        }
      ],
      allAlign: 'center',
      selectMeetUser: [],
      selectedMeetUser: []
    }
  },
  created() {
    this.getDepartOrNameByBdst()
  },
  methods:{
    getDepartOrNameByBdst(){
      this.axios.post('/meetuser/getDepartOrNameByBdst', (response) => {
        console.log(response)
        this.selectMeetUser = response.obj
      },{
        bdst: this.meetbdst
      })
    },
    getSelectionEvent(row){
      let arr;
      arr = {name: row.name};
      if (this.selectedMeetUser.indexOf(row.name) === -1){
        this.selectedMeetUser.push(row.name);
      }else {
        ElMessage.error('请不要重复选择!');
      }


    },
    delMeetUser(item){
      let index = this.selectedMeetUser.indexOf(item);
      this.selectedMeetUser.splice(index, 1);
    },
    upMeet(){
      if (this.meetname.length > 7){
        ElMessage.error('会议名称过长，请精简到7个字之内！');
      }else{
        this.axios.post('/meetMain/saveMeetMainAndBranch', (response) => {

        },{
          startUnit: this.meetdw,
          meetName: this.meetname,
          meetTime: this.datetime + ' ' + this.detailtime,
          meetUsers: JSON.stringify(this.selectedMeetUser)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>